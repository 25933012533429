<template>
  <div class="aditional-purchaseInfo-container">
    <div class="order-header" v-loading="basicLoading">
      <div class="header-title">
        <span>加购订单号：{{ detailsInfo.orderNo }}</span>
        <span class="request-refund" style="background-color:#fcefec; color: #E86247" v-if="detailsInfo.orderStatus === 1">待支付</span>
        <span class="request-refund" style="background-color:#FCF3DE; color: #E3904A" v-if="detailsInfo.orderStatus === 2">已付款</span>
        <span class="request-refund" style="background-color:#E9EFFC; color: #2861E2" v-if="detailsInfo.orderStatus === 3">部分消耗</span>
        <span class="request-refund" style="background-color:#E5F5F4; color: #00A199" v-if="detailsInfo.orderStatus === 4">已完成</span>
        <span class="request-refund" style="background-color:#EDEDED; color: #000000" v-if="detailsInfo.orderStatus === 5">已取消</span>
        <span class="request-refund" style="background-color:#EDEDED; color: #000000" v-if="detailsInfo.orderStatus === 6">已关闭</span>
        <span style="margin-left:20px" v-if="detailsInfo.orderStatus == 1 && detailsInfo.countdownMinute">
          剩余：{{ detailsInfo.countdownMinute > 9 ? detailsInfo.countdownMinute : '0' + detailsInfo.countdownMinute }}:{{
            detailsInfo.countdownSecond > 9 ? detailsInfo.countdownSecond : '0' + detailsInfo.countdownSecond
          }}自动取消
        </span>
      </div>
      <el-descriptions :column="1">
        <el-descriptions-item label="关联服务单号">
          <span style="cursor: pointer;" @click="$router.back()">{{ detailsInfo.serviceNo }}</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="5">
        <el-descriptions-item label="创建时间">{{ detailsInfo.createTime }}</el-descriptions-item>
        <el-descriptions-item label="付款时间" v-if="detailsInfo.orderStatus == 2 || detailsInfo.orderStatus == 3 || detailsInfo.orderStatus == 4 || detailsInfo.orderStatus == 6">
          {{ detailsInfo.paymentTime }}
        </el-descriptions-item>
        <el-descriptions-item label="订单完成时间" v-if="detailsInfo.orderStatus == 4">{{ detailsInfo.finishTime }}</el-descriptions-item>
        <el-descriptions-item label="订单取消时间" v-if="detailsInfo.orderStatus == 5">{{ detailsInfo.cancelTime }}</el-descriptions-item>
        <el-descriptions-item label="订单关闭时间" v-if="detailsInfo.orderStatus == 6">{{ detailsInfo.closeTime }}</el-descriptions-item>
      </el-descriptions>
      <div class="header-button-group">
        <el-button type="primary" @click="handleOperate('searchPayLink')" v-if="detailsInfo.orderStatus == 1">查看付款码</el-button>
        <el-button class="refuse" type="primary" @click="handleOperate('cancelOrder')" v-if="detailsInfo.orderStatus == 1">取消订单</el-button>
      </div>
    </div>
    <div class="header-content">
      <ul class="flex tab-list">
        <li class="tab-list-item" :class="item.checked ? 'item-checked' : ''" v-for="(item, i) in tabList" :key="i" @click="handleTab(item)">
          {{ item.name }}
          <span v-if="item.num">({{ item.num }})</span>
        </li>
      </ul>
      <div class="header-tab-content">
        <aditionalDetailBasicInfo :orderDetailInfo="detailsInfo" :addProductInfos="addProductInfos" v-loading="basicLoading" v-if="currenTab.name === '基础信息'" />
        <aditionalOrderRecord style="width:40%" :orderReords="orderReords" v-loading="orderReordsLoading" v-if="currenTab.name === '订单记录'" />
      </div>
    </div>
    <CancelOrderPopups popupsTitle="取消订单" :currentData="currentData" :showState="isCancelOrder" v-on:hidePopups="isCancelOrder = false" />
    <SearchPayOrderPopups popupsTitle="请客户扫描下方付款码完成付款" :currentData="currentData" :showState="isSearchPayOrder" v-on:hidePopups="isSearchPayOrder = false" />
  </div>
</template>

<script>
import aditionalDetailBasicInfo from './components/aditionalDetailBasicInfo';
import aditionalOrderRecord from './components/aditionalOrderRecord';
import CancelOrderPopups from './components/cancelOrder';
import SearchPayOrderPopups from './components/searchPayOrder';
import { getOrderRecordDetail, getDetailAddOrderList, getOrderRecordList } from '@/api/order';

export default {
  components: {
    aditionalDetailBasicInfo,
    aditionalOrderRecord,
    CancelOrderPopups,
    SearchPayOrderPopups
  },
  data() {
    return {
      serviceId: undefined,
      orderId: undefined,
      basicLoading: false,
      orderReordsLoading: false,
      detailsInfo: {},
      addProductInfos: [],
      orderReords: [],
      tabList: [
        { name: '基础信息', num: 0, checked: true },
        { name: '订单记录', num: 0, checked: false }
      ],
      currenTab: { name: '基础信息', num: 0, checked: true },
      isCancelOrder: false,
      isSearchPayOrder: false,
      currentData: {}
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.basicLoading = true;

        this.serviceId = this.$route.query.serviceId;
        this.orderId = this.$route.query.orderId;

        let detailRes = (await getOrderRecordDetail({ data: this.orderId })).data || {};
        if (detailRes.orderStatus == 1) {
          detailRes['countdownMinute'] = (detailRes.overdueTime - (detailRes.overdueTime % 60)) / 60;
          detailRes['countdownSecond'] = detailRes.overdueTime % 60;

          if (detailRes.countdownMinute > 0) {
            let countdownSetInterval = setInterval(() => {
              detailRes.countdownSecond--;
              if (detailRes.countdownSecond == 0) {
                detailRes.countdownSecond = 60;
                detailRes.countdownMinute--;
              }
              if (detailRes.countdownMinute == 0) {
                clearInterval(countdownSetInterval);
                delete detailRes.countdownMinute;
                delete detailRes.countdownSecond;
              }
            }, 1000);
          }
        }
        // console.log(JSON.parse(JSON.stringify(detailRes)));
        this.detailsInfo = detailRes;

        this.addProductInfos = (await getDetailAddOrderList({ data: { orderId: this.orderId } })).data.content || [];

        this.basicLoading = false;
      } catch (error) {
        // console.log(error);
        this.basicLoading = false;
      }
    },
    async handleTab(item) {
      let currenObj = this.tabList.find(item => item.checked);
      currenObj.checked = false;
      item.checked = !item.checked;
      this.currenTab = item;
      switch (item.name) {
        case '基础信息':
          this.getData();
          break;
        case '订单记录':
          try {
            this.orderReordsLoading = true;

            this.orderReords = (await getOrderRecordList({ data: this.orderId })).data || [];

            this.orderReordsLoading = false;
          } catch (error) {
            this.orderReordsLoading = false;
          }

          break;
      }
    },
    handleOperate(mark) {
      switch (mark) {
        case 'searchPayLink':
          this.currentData = {
            orderNo: this.detailsInfo.orderNo,
            orderId: this.detailsInfo.orderId
          };
          this.isSearchPayOrder = true;
          break;
        case 'cancelOrder':
          this.currentData = {
            orderId: this.detailsInfo.orderId
          };
          this.isCancelOrder = true;
          break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.aditional-purchaseInfo-container {
  .request-refund {
    position: relative;
    margin-left: 12px;
    // padding: 0 6px 0 12px;
    padding: 0 12px;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    // &::before {
    //   content: '';
    //   position: absolute;
    //   top: 8px;
    //   left: 4px;
    //   width: 4px;
    //   height: 4px;
    //   background-color: #fff;
    //   border-radius: 50%;
    // }
  }
  .refuse {
    background-color: #fff;
    color: #2861e2;
    border: 1px solid #2861e2;
    &:hover {
      opacity: 0.8;
    }
  }
  .order-header {
    padding: 20px;
    background-color: #fff;
    .header-title {
      margin-bottom: 16px;
    }
    .header-button-group {
      margin-top: 30px;
    }
  }
  .header-content {
    margin-top: 16px;
    background-color: #fff;
    .tab-list {
      font-size: 14px;
      color: #9a9d9e;
      border-bottom: 1px solid #ededed;
      .tab-list-item {
        margin-left: 30px;
        padding: 16px 0;
        &:hover {
          cursor: pointer;
          color: #000000;
          border-bottom: 3px solid #2861e2;
        }
      }
      .item-checked {
        color: #000000;
        border-bottom: 3px solid #2861e2;
      }
    }
  }
}
</style>
